import React, {useEffect, useState} from 'react'
import { Form } from 'react-bootstrap'
import { Auth, Hub } from 'aws-amplify'
import btn from '../assets/btn_google_signin_dark_pressed_web@2x.png'
import { useAppContext } from "../lib/contextLib";
import './Login.css'
import LoaderButton from './LoaderButton';

function getUser() {
    console.log(`in getUser()`)
    return Auth.currentAuthenticatedUser()
      .then(userData => userData ? {
        email: userData.attributes.email,
        jwt: userData.signInUserSession.accessToken.jwtToken
      } : undefined)
      .catch(() => console.log('Not signed in'))
  }

export default function Login() {

    const { userHasAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState(false);

    const [acceptPrivacy, setAcceptPrivacy] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
  
    const handlePrivacyChange = () => {
      setAcceptPrivacy(!acceptPrivacy);
    };
  
    const handleTermsChange = () => {
      setAcceptTerms(!acceptTerms);
    };


useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => userHasAuthenticated(true))
          break
        case 'signOut':
          //dispatch(setUser(null))
          userHasAuthenticated(false)
          break
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data)
          break
        default:
          console.log('Error: unrecognized event', event)
          break
      }
    })
    console.log(`getuser() from useEffect`)
    getUser().then(userData => setUser(userData))
  },[])


  function handleLogin() {

    if (acceptPrivacy && acceptTerms) {
      //alert('Form submitted successfully!');
    } else {
      alert('Please accept both privacy and terms before login.');
    }    

    try {
      localStorage.clear();
      Auth.federatedSignIn({ provider: 'Google' })
    }
    catch(err) {
      console.log(`ERROR:: ${JSON.stringify(err)}`)
    }
  }

  return (
    <div className="Login">
        <center>
          <h3>Please Authenticate to continue</h3>
        <br/>            
          <LoaderButton disabled={!acceptPrivacy || !acceptTerms ? true : false} onClick={() => handleLogin() }>
              <img src={btn} alt="Google Sign In button"
                  className="googleSignIn"
                  style={{ height: '45px', width: '190px' }}/>
          </LoaderButton>
        </center>
        <br/><br/>
        
        <div key={`default`} className="mb-3">
        <p style={{textAlign: "left"}} className="text-muted">Before Login, please accept the privacy policy and the terms of service. Please do not login, if you do not agree with the privacy policy and terms.</p>
          <Form.Group controlId="acceptPrivacy">
          <Form.Check
            type="checkbox"
            label={
              <span>
                By checking this box I agree that I have read and agree to the {' '} <a href="/privacy">Privacy Policy</a>
              </span>}
            checked={acceptPrivacy}
            onChange={handlePrivacyChange}
            style={{ textAlign: "left", color: "white" }}
          />
        </Form.Group>
        <Form.Group controlId="acceptTerms">
          <Form.Check
            type="checkbox"
            label={
              <span>
                By checking this box I agree that I have read and agree to the {' '} <a href="/terms">Terms of Service</a>
              </span>}            
              checked={acceptTerms}
            onChange={handleTermsChange}
            style={{ textAlign: "left", color: "white" }}
          />
        </Form.Group>
        
        </div>
        
        

    </div>
  )
}

import React from 'react'
import { useParams } from 'react-router-dom';

import { useState, useEffect, useRef } from 'react'
import FlippableCard from './flippable-card'
import { Button } from 'react-bootstrap'
import axios from 'axios';
import { Auth } from "aws-amplify";
import { useSwipeable } from 'react-swipeable';
import LoaderButton from './LoaderButton';
import './flashCardList.css'
import config from '../config';
const BASE_URL = config.API_BASE_PATH

export default function FlashcardList() {
  const { mainId } = useParams();
  //console.log(`mainId = ${mainId}`)
  const [categories, setCategories] = useState([])
  const [groups, setGroups] = useState([])
  const [user, setUser] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedGroup, setSelectedGroup] = useState('')

  const [swiping, setSwiping] = useState(false);
  const [offset, setOffset] = useState(0); // State to control the transformation
  const [isAddingWord, setIsAddingWord] = useState(false);

  //const [errorMessage, setErrorMessage] = useState("")
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingGroups, setLoadingGroups] = useState(false);

  const [flashCards, setFlashCards] = useState(SAMPLE_FLASHCARDS2)
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showFront, setShowFront] = useState(true)
  const categoryEl = useRef()
  const groupEl = useRef()

  const handlers1 = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrevious(),
  });


  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleNext();
      setSwiping(true);
      //setOffset(-100); // Apply a transformation to move to the next card
    },
    onSwipedRight: () => {
      handlePrevious();
      setSwiping(true);
      //setOffset(100); // Apply a transformation to move to the previous card
    },
    onSwiped: () => {
      // Reset the transformation and swiping state after the swipe is complete
      setTimeout(() => {
        setSwiping(false);
        setOffset(0);
      }, 300); // Adjust the timeout to match your transition duration
    },
  });


  useEffect(() => {

    async function onLoad() {
      console.log('In the List onLoad Function')
      let userItem
      try {
        userItem = await Auth.currentAuthenticatedUser()
        //console.log(`JWT UI: ${userItem?.signInUserSession?.accessToken?.jwtToken}`)
        setUser(userItem)
        //console.log(`JWT: ${user?.signInUserSession?.accessToken?.jwtToken}`)
      }
      catch(err) {
        console.log("No Authenticated User")
        window.location.href = "/login"
      }

      setLoadingCategories(true)
      setSelectedCategory("")
      setSelectedGroup("")
      const requestInfo = {
        headers: {
          Authorization: `Bearer ${userItem?.signInUserSession?.accessToken?.jwtToken}`,
          'Content-Type': 'application/json'
        }
      }  
  
        console.log(JSON.stringify(requestInfo))
  
        try {
          let allCats

          let allCatsStr = localStorage.getItem('allCats')
          if(allCatsStr != null) {
            try {
              allCats = JSON.parse(allCatsStr)
            }
            catch(err) {
              console.log(`Error All Cats`)
            }
          }

          if (!allCats) {
            const allCatsResponse = await axios.get(`${BASE_URL}/vocab/categories/?id=${mainId}`, requestInfo)
            allCats = allCatsResponse.data
            //localStorage.setItem('allCats', JSON.stringify(allCatsResponse.data));          
          }

          if(mainId === 'mylist') {
            allCats.push({id: "MyList", name: "My List"})
          }
            
          setCategories(allCats)
          setLoadingCategories(false)

          let catalogDataStr = localStorage.getItem('catalogData')
          let catalogData
          if(catalogDataStr != null) {
            try{
              catalogData = JSON.parse(catalogDataStr)    
            }
            catch(err) {

            }

            if(catalogData) {
              if(catalogData.catId && catalogData.groupId) {
                console.log(`Stored Category Id: ${catalogData.catId},  Group Id: ${catalogData.groupId}`)
                categoryEl.current.value = catalogData.catId
                setSelectedCategory(catalogData.catId)

                setLoadingGroups(true)
                setGroups([])
                //cat groups data
                let catGroups
                let catGroupsStr = localStorage.getItem('catGroups')
                
                if(catGroupsStr != null) {
                  try{
                    catGroups = JSON.parse(catGroupsStr)    
                  }
                  catch(err) {
                    console.log(`Error loading catgroups`)
                  }
                }

                if (!catGroups) {
                  const catGroupsResponse = await axios.get(`${BASE_URL}/vocab/${catalogData.catId}/groups`, requestInfo)
                  catGroups = catGroupsResponse.data
                  //localStorage.setItem('catGroups', JSON.stringify(catGroups));          
                } 
                console.log(`catGroups: ${JSON.stringify(catGroups)}`)
                setGroups(catGroups.groups)
                setLoadingGroups(false)
                groupEl.current.value = catalogData.groupId
                setSelectedGroup(catalogData.groupId)

                let catGroupsData
                // let catGroupsDataStr = localStorage.getItem('catGroupsData')

                // if(catGroupsDataStr != null) {
                //   try {
                //     catGroupsData = JSON.parse(catGroupsDataStr)
                //   }
                //   catch(err) {
                //     console.log(`Error while cat groups data`)
                //   }
                // }

                if(!catGroupsData) {
                  let catGroupsDataResponse = await axios.get(`${BASE_URL}/vocab/${catalogData.catId}/groups/${catalogData.groupId}/data`, requestInfo)
                  catGroupsData = catGroupsDataResponse.data
                  //localStorage.setItem('catGroupsData', JSON.stringify(catGroupsData));  
                }

                setCurrentIndex(0)
                setFlashCards(catGroupsData.items)
                setShowFront(true)

              }
              else if(catalogData.catId) {
                console.log(`Stored Category Id: ${catalogData.catId}`)
                categoryEl.current.value = catalogData.catId
                setSelectedCategory(catalogData.catId)

                // setLoadingGroups(true)
                // setGroups([])
                setCurrentIndex(0)
                //console.log(`categoryEl.current.value = ${categoryEl.current.value}`)
            
                setSelectedCategory(catalogData.catId)

                setLoadingGroups(true)
                setGroups([])
                //cat groups data
                let catGroups
                //commenting it, becasue when user goes from free to paid, we need to make the call
                // let catGroupsStr = localStorage.getItem('catGroups')
                
                // if(catGroupsStr != null) {
                //   try{
                //     catGroups = JSON.parse(catGroupsStr)    
                //   }
                //   catch(err) {
                //     console.log(`Error loading catgroups`)
                //   }
                // }

                if (!catGroups) {
                  const catGroupsResponse = await axios.get(`${BASE_URL}/vocab/${catalogData.catId}/groups`, requestInfo)
                  catGroups = catGroupsResponse.data
                  //localStorage.setItem('catGroups', JSON.stringify(catGroups));          
                } 
                console.log(`catGroups: ${JSON.stringify(catGroups)}`)
                setGroups(catGroups.groups)
                setLoadingGroups(false)
              }
            }
          }

            //Make backend calls
        }
        catch(error) {
          console.log(`Error getting categories: ${JSON.stringify(error)}`)
        }
    }
  
    onLoad()
  }, [])

  async function addToList(word, type) {
    
    setIsAddingWord(true)
    try {
      let userItem = await Auth.currentAuthenticatedUser()
      //console.log(`JWT UI: ${userItem?.signInUserSession?.accessToken?.jwtToken}`)
      setUser(userItem)
      //console.log(`JWT: ${user?.signInUserSession?.accessToken?.jwtToken}`)
    }
    catch(err) {
      console.log("No Authenticated User")
      window.location.href = "/login"
    }

    console.log(word + ":" + type)
    const requestInfo = {
      headers: {
        Authorization: `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`,
        'Content-Type': 'application/json'
      }
    } 
    const payload = {
      word: word,
      type: type
    }
    let response
    try {
       response = await axios.post(`${BASE_URL}/member/addWord`, payload, requestInfo)

      console.log(`response = ${JSON.stringify(response)}`)
      if(response.status === 200) {
        alert("Word Added Successfully")
      }

    }
    catch (err) {
      console.log(err)
      alert(err?.response?.data)

    }   
    setIsAddingWord(false) 

  }


  function handleSubmitCategory(e) {
    //e.preventDefault()
    console.log(`In handle Submit Category`)
    if (categoryEl.current.value) {
      setFlashCards(SAMPLE_FLASHCARDS3)
      // localStorage.setItem('catalogData', JSON.stringify({
      //   "catId": categoryEl.current.value
      // }));
      setSelectedCategory(categoryEl.current.value)
    }
    else {
      setFlashCards(SAMPLE_FLASHCARDS2)
      localStorage.removeItem('catalogData')
      setSelectedCategory('')
    }
    setLoadingGroups(true)
    setGroups([])
    
    setCurrentIndex(0)
    //console.log(`categoryEl.current.value = ${categoryEl.current.value}`)

    const requestInfo = {
      headers: {
        Authorization: `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`,
        'Content-Type': 'application/json'
      }
    } 
    axios
    .get(`${BASE_URL}/vocab/${categoryEl.current.value}/groups` , requestInfo)
    .then(res => {
      //console.log(`Res Http Status: ${JSON.stringify(res)}`)
      setGroups(res.data.groups)
      setLoadingGroups(false)
      // localStorage.setItem('catGroups', JSON.stringify({
      //   catId: categoryEl.current.value,
      //   groups: res.data
      // }))      
      //console.log(`Groups:: ${JSON.stringify(res.data)}`)
    })
    .catch(err => {
      console.log(`Error Groups: ${JSON.stringify(err)}`)
      window.location.href = '/login'
      
    })
  }

  async function handleSubmitGroup(e) {
    console.log(`In handle Submit Group`)
    //e.preventDefault()
    //if (categoryEl.current.value == )
    //console.log(`categoryEl.current.value = ${categoryEl.current.value}, groupEl.current.value = ${groupEl.current.value}`)
    
    setCurrentIndex(0)

    const requestInfo = {
      headers: {
        Authorization: `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`,
        'Content-Type': 'application/json'
      }
    }

    if(groupEl.current.value !== '') {
      setFlashCards(SAMPLE_FLASHCARDS4)
      setSelectedGroup(groupEl.current.value)
      // localStorage.setItem('catalogData', JSON.stringify({
      //   "catId": categoryEl.current.value,
      //   "groupId": groupEl.current.value
      // }));

      axios
      .get(`${BASE_URL}/vocab/${categoryEl.current.value}/groups/${groupEl.current.value}/data`, requestInfo)
      .then(res => {
        //console.log(`Groups Data:: ${JSON.stringify(res.data)}`)
        console.log(`Res Http Status: ${res.status}`)
        // localStorage.setItem('catGroupsData', JSON.stringify({
        //   catId: categoryEl.current.value,
        //   groups: groupEl.current.value,
        //   items: res.data.items
        // }))
        //alert(JSON.stringify(res))
        setCurrentIndex(0)        
        setFlashCards(res.data.items)
        setShowFront(true)

      })
      .catch(err => {
        console.log(`Error Groups Data: ${JSON.stringify(err)}`)
        window.location.href = '/login'
        
      })   
    }
    else {
      setFlashCards(SAMPLE_FLASHCARDS3)
      setSelectedGroup('')
      if(categoryEl.current.value) {
        // localStorage.setItem('catalogData', JSON.stringify({
        //   "catId": categoryEl.current.value
        // }));
      }
      else {
        localStorage.removeItem('catalogData')
      }
    }
  }
  
  const handleNext = () => {
    if (currentIndex < flashCards.length - 1) {
      setShowFront(true)
      setCurrentIndex(currentIndex + 1);
    }
    else {
      setShowFront(true)
      setCurrentIndex(0);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setShowFront(true)
      setCurrentIndex(currentIndex - 1);
    }
    else {
      setShowFront(true)
      setCurrentIndex(flashCards.length - 1);
    }
  };


    return (
        <center>
        <div id="mainDiv" style={{ fontSize: '13px', color: 'white', paddingBottom: '3px', }}>
            
            <select id="category" style={{fontWeight: 'bold'}} ref={categoryEl} onChange={() => handleSubmitCategory()} value={selectedCategory}>

            {loadingCategories ? (
              <option>Loading...</option>
            ) :
            (
                <>
                <option value="" key="0-cat">Pick a category</option>
                {categories?.map(category => {
                    return <option value={category.id} key={category.id}>{category.name}</option>
                  })}
                </>
                )}
              </select>
                  &nbsp;
           
            <select id="group" style={{fontWeight: 'bold'}} ref={groupEl} onChange={() => handleSubmitGroup()}  value={selectedGroup}>

            {loadingGroups ? (
              <option>Loading...</option>
            ) :
              (
                <>
              <option value="" key="0-group">Pick a group</option>
              {groups?.map(group => {
                  return <option value={group.groupId} key={group.id}>{group.name}</option>
                })}
                </>
              )}
            </select>  

        </div>
        {/* <div className={`listCardContainer ${swiping ? 'card-container' : ''}`} style={{ transform: `translateX(${offset}%)` }} {...handlers}> */}

        <div className="listCardContainer" {...handlers}>
            
            <div>
                <FlippableCard showFront={showFront} flashCard={flashCards[currentIndex]} setShowFront={setShowFront} key={flashCards[currentIndex].word}/>
            </div>

            <div className="button-container">
                <Button className="left-button" variant="primary"
                    onClick={handlePrevious}
                    > Prev </Button>
                <span style={{ color: 'white'}}>{currentIndex + 1} / {flashCards.length}</span>
                
                <Button className="right-button" variant="primary"
                    onClick={handleNext}
                    > Next </Button>
                {selectedCategory && selectedCategory !== 'MyList' ? (
                  <LoaderButton className="middle-button" variant="primary" 
                  isLoading={isAddingWord}
                  disabled={isAddingWord ? true : false}
                  onClick={() => addToList(flashCards[currentIndex].word,flashCards[currentIndex].type)}
                  >Add To List</LoaderButton>
                ):('')}
                 
            </div>

        </div> 
        </center>

  )
}

const SAMPLE_FLASHCARDS2 = [
    {
      "word": "Pick A Category And Group",
      "meaning": "",
      "examples": [
        
      ]
    },
  ]

  const SAMPLE_FLASHCARDS3 = [
    {
      "word": "Pick A Group",
      "meaning": "",
      "examples": [
        
      ]
    },
  ]  

  const SAMPLE_FLASHCARDS4 = [
    {
      "word": "Loading...",
      "meaning": "",
      "examples": [
        
      ]
    },
  ] 
  

export function onError(error) {
    //console.log('Error:', error.toString())  
    let message = error.toString();
      
      // Auth errors
      if (!(error instanceof Error) && error.message) {
        message = error.message;
      }
    
      //alert(message);
    }
  
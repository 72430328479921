import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import "./MyLists.css";
import Sidebar from "./Sidebar";

export default function MyLists() {
  return (

    <div className="d-flex">
      <div className="col-auto">
        <Sidebar />
      </div>

      <div className="text-center w-75 mx-auto">
        <h1 className="text-white fs-3 pt-4">Manage Lists</h1>
        <p class="fs-6 text-muted"> 
          You can manage your own lists here. As you are learning words by visiting various categories, groups and search, you can add them to your own custom list.
        </p>

        <hr className="text-muted"/>
        <main>
          <div className="row row-cols-1 row-cols-md-3">
            
            <div className="col mt-2">
              <div className="card text-center">
                <div className="card-header">
                  <h4 className="fw-bold fs-5">12 Months<span className="text-success">**</span></h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title fs-5">
                    $99 <small className="text-muted fw-light">/12mo (recurring)</small>
                  </h1>
                  <button className="btn btn-lg btn-success  w-100 text-white">Purchase</button>
                </div>
              </div>
            </div>

            <div className="col mt-2">
              <div className="card text-center">
                <div className="card-header">
                <h4 className="fw-bold fs-5">6 Months</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title fs-5">
                    $59 <small className="text-muted fw-light">/6mo (recurring)</small>
                  </h1>
                  <button className="btn btn-lg btn-success  w-100 text-white">Purchase</button>
                </div>
              </div>
            </div>

            <div className="col mt-2">
              <div className="card text-center">
                <div className="card-header">
                  <h4 className="fw-normal">3 Months</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title fs-5">
                    $39 <small className="text-muted fw-light">/3mo (recurring)</small>
                  </h1>
                  <button className="btn btn-lg btn-success  w-100 text-white">Purchase</button>
                </div>
              </div>
            </div>            

            <div className="col mt-2">
              <div className="card text-center">
                <div className="card-header">
                  <h4 className="fw-normal">1 Month</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title fs-5">
                    $19 <small className="text-muted fw-light">/mo (recurring)</small>
                  </h1>
                  <button className="btn btn-lg btn-success  w-100 text-white">Purchase</button>
                </div>
              </div>
            </div> 
          </div>
        </main>
        <hr className="text-muted"/>

        <h2 className="text-warning fs-5" style={{textAlign: "left"}}>Subsciption Plans Highlights</h2>
        <div className="text-info">
        <ul className="list py-3">
          <li>
            Access the words, meaning, and upto 3 example uses.
          </li>
          <li>
            Almost all words have associated sound that includes word, spelling, meaning and examples.
          </li> 
          <li className="">
            Create upto 5 lists of 25 words each that you can add words too, for last minute learning. <br/>As you go through the words, you can add them to your lists for frequent reviews.
          </li>  
          <li>
            All plans are recurring until cancelled.
          </li> 
          <li>
            Cancel any time!
          </li>                            
        </ul>
        </div>
        <hr className="text-muted"/>
        <p className="text-success fs-7" style={{textAlign: "left"}}>** Most Popular /  Best value plan</p>


      </div>




    </div>
  );
}

import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from 'axios';
import { Auth } from "aws-amplify";
import "./MyAccount.css";
import LoaderButton from './LoaderButton'

import config from '../config';
const BASE_URL = config.API_BASE_PATH

export default function MySubscriptions() {

  const [priceData, setPriceData] = useState(null)
  const [user, setUser] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null)
  const [isPurchasing, setIsPurchasing] = useState(false)
  useEffect(() => {

    async function onLoad() {
      console.log('In the Subscription onLoad Function')
      try {
        let user = await Auth.currentAuthenticatedUser()
        setUser(user)

        if (!user) {
          //redirect
          window.location = "/login"
        }

        const requestInfo = {
          headers: {
            Authorization: `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`,
            'Content-Type': 'application/json'
          }
        }
        const subscriptionStatus = await axios.get(`${BASE_URL}/member/checkSubscriptionStatus`, requestInfo)
        console.log(`subscriptionStatus == ${JSON.stringify(subscriptionStatus)}`)
        setSubscriptionStatus(subscriptionStatus?.data)

      }
      catch (err) {
        console.log(`Error getting user from session ${JSON.stringify(err)}`)
        //window.location = "/login"
        if(!user) {
          window.location = "/login"
        }
      }

    }
    onLoad()
  }, [])

  useEffect(() => {

    const prices = {
      "prices": config.prices
    }

    setPriceData(prices)


  }, [])


  async function startCheckout(planId) {
    console.log(`PlanId: ${planId}`)
    setIsPurchasing(true)

    if (subscriptionStatus?.activeSubscription && subscriptionStatus?.currentSubscription?.id === planId) {
      window.location = subscriptionStatus.customerPortalLink
    }

    const requestInfo = {
      headers: {
        Authorization: `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`,
        'Content-Type': 'application/json'
      }
    }

    const payload = {
      "planId": planId
    }

    try {
      let response = await axios.post(`${BASE_URL}/member/createCheckoutSession`, payload, requestInfo)

      console.log(`response = ${JSON.stringify(response)}`)

      window.location = response.data.url;
    }
    catch (err) {
      console.log(err)
    }

  }


  return (

    <div className="d-flex">


      <div className="text-center w-75 mx-auto">
        <h1 className="text-white fs-3 pt-4">My Subscriptions</h1>
        <p className="text-white fs-3 pt-3">
          Use Promo Code <span class="text-info">LAUNCHSPECIAL</span> on the checkout page, for 50% discount.
        </p>
        <p className="fs-6 text-muted">
          Manage your subscription here. You can purchase, change and cancel the subcription plan.
        </p>
        {subscriptionStatus?.activeSubscription && (
          <p className="fs-6 text-white">
            You are currently subscribed to "{subscriptionStatus?.currentSubscription.name}". Your subscription is valid until "{subscriptionStatus?.end_date}"
          </p>

        )}
        {!subscriptionStatus?.activeSubscription && subscriptionStatus?.end_date && (
          <p className="fs-6 text-white">
            Your subscription ended on "{subscriptionStatus?.end_date}"
          </p>
        )}

        <hr className="text-muted" />
        {!subscriptionStatus && (
            <main>
            <div className="row row-cols-1 row-cols-md-2">
            <p className="fs-6 text-white">Loading ...</p>
            </div>
            </main>          
        )}
        {subscriptionStatus && (
          <>
            <main>
              <div className="row row-cols-1 row-cols-md-2">

                {priceData?.prices?.map((item, idx) => (

                  <div className="col mt-2" key={item.id}>
                    <div className="card text-center">
                      <div className="card-header">
                        <h4 className="fw-bold fs-5">{item.name}{item.id === "12mo" && <span className="text-success">**</span>}</h4>
                      </div>
                      <div className="card-body">
                        <h1 className="card-title fs-5">
                          ${item.price} <small className="text-muted fw-light">/{item.billFreq}{item.billFreqUnit}{item.billFreq === '1' ? '': 's'}</small><br />
                          <small className="text-muted fw-light">{item.description}</small><br />

                        </h1>
                        <LoaderButton
                          onClick={() => startCheckout(item.id)}
                          isLoading={isPurchasing}
                          disabled={subscriptionStatus?.activeSubscription ? (item.id === subscriptionStatus?.currentSubscription?.id ? false : true) : false}
                          className="btn btn-lg btn-success w-100 text-white">{subscriptionStatus?.activeSubscription ? (item.id === subscriptionStatus?.currentSubscription?.id ? "Manage" : "Subscribe"): "Subscribe"}</LoaderButton>
                      </div>
                    </div>
                  </div>

                ))}

              </div>
            </main>

            <hr className="text-muted" />

            <h2 className="text-warning fs-5" style={{ textAlign: "left" }}>Subsciption Plans Highlights</h2>
            <div className="text-info">
              <ul className="list py-3">
                <li>
                  Access the words, meaning, and upto 3 example uses.
                </li>
                <li>
                  Almost all words have associated sound that includes word, spelling, meaning and examples.
                </li>
                <li className="">
                  Create a list of upto 25 words that you can add words too, for last minute learning. <br />As you go through the words / search, you can add them to your lists for frequent reviews.
                </li>
                <li>
                  All plans are recurring until cancelled.
                </li>
                <li>
                  You have the flexibility to cancel your subscription at any time. Your subscription will remain active until the end of the current billing period, and no additional charges will be applied thereafter.
                </li>
                {/* <li>
                  Please cancel within trial period to avoid getting charged. NO REFUNDS will be given.
                </li> */}
              </ul>
            </div>
            <hr className="text-muted" />
            <p className="text-success fs-7" style={{ textAlign: "left" }}>** Most Popular /  Best value plan</p>
          </>
        )}




      </div>




    </div>
  );
}

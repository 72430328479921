
import "./Terms.css";
export default function Terms() {


    return (
        <>
            <div className="d-flex">
                <div className="text-center w-75 mx-auto text-white">
                    <h1 className="text-white fs-3 pt-4">Website Terms and Conditions of Use</h1>
                    <p className="fs-6 text-muted">
                        Last Updated: [October 24th, 2023]
                    </p>

                    <div className="text-white" style={{textAlign: 'left'}}>

                    <h2 className="text-warning">1. Acceptance of Terms</h2>
                    <p>By accessing or using this website ("vocabwhiz.com") and any services or content provided by VocabWhiz LLC. ("Company"), you agree to comply with and be bound by these Terms and Conditions of Use ("Terms"). If you do not agree to these Terms, please do not use this Site.</p>

                    <h2 className="text-warning">2. Subscription Service</h2>
                    <p>a. The Company offers subscription-based services ("Service") accessible through this Site. By subscribing to the Service, you agree to pay the applicable fees and comply with any additional terms provided during the subscription process.</p>
                    <p>b. Your subscription is personal to you and may not be transferred or shared with others without the Company's express written consent.</p>

                    <h2 className="text-warning">3. Intellectual Property</h2>
                    <p>a. All content and materials on this Site, including but not limited to text, graphics, logos, images, audio, video, and software, are the intellectual property of the Company and are protected by copyright, trademark, and other intellectual property laws.</p>
                    <p>b. You may not reproduce, copy, modify, distribute, or display any content from this Site without the Company's prior written consent.</p>

                    <h2 className="text-warning">4. Privacy</h2>
                    <p>a. Your use of this Site and the Service is subject to our Privacy Policy, which can be found <a href="/privacy">Here</a>.</p>

                    <h2 className="text-warning">5. Termination</h2>
                    <p>a. The Company reserves the right to terminate or suspend your access to the Site and Service at its sole discretion, without prior notice, for any reason, including but not limited to violation of these Terms.</p>

                    <h2 className="text-warning">6. Disclaimers</h2>
                    <p>a. This Site and the Service are provided "as is" and "as available" without warranties of any kind, either express or implied. The Company makes no representations or warranties regarding the accuracy, completeness, or availability of the Site or Service.</p>

                    <h2 className="text-warning">7. Limitation of Liability</h2>
                    <p>a. To the extent permitted by law, the Company shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from your use of the Site or Service, even if the Company has been advised of the possibility of such damages.</p>

                    <h2 className="text-warning">8. Governing Law</h2>
                    <p>a. These Terms shall be governed by and construed in accordance with the laws of Los Angeles, California, without regard to its conflict of law principles.</p>

                    <h2 className="text-warning">9. Changes to Terms</h2>
                    <p>a. The Company reserves the right to modify or update these Terms at any time, and such changes will be effective immediately upon posting on the Site. It is your responsibility to review these Terms periodically.</p>

                    <h2 className="text-warning">10. Contact Information</h2>
                    <p>a. For questions or concerns regarding these Terms, please contact us at <span className="text-info">contact@vocabwhiz.com</span>.</p>


                    </div>


                </div>

            </div>
        </>
    )

}


import "./RefundPolicy.css";
export default function RefundPolicy() {


    return (
        <>
            <div className="d-flex">
                <div className="text-center w-75 mx-auto text-white">
                    <h1 className="text-white fs-3 pt-4">Refund Policy</h1>
                    <p className="fs-6 text-muted">
                        Last Updated: [October 24th, 2023]
                    </p>

                    <div className="text-white" style={{textAlign: 'left'}}>    
                    <p>Thank you for choosing <span className="text-info">vocabwhiz.com</span>! We value your subscription and are committed to providing you with an excellent service experience. Please take a moment to read our refund policy carefully.</p>

                    <h2 className="text-warning">1. Free Account / Premium Service Preview</h2>
                    <p>Vocabwhiz.com offers users the opportunity to experience a reduced scope of our premium services before committing to a paid subscription. This allows you to evaluate our offerings and understand the benefits of a full premium subscription.</p>
                    <p>During this preview period, you will have access to all premium features in reduced scope.</p>
                    <p>No charges will be applied to your account during the free / preview period.</p>

                    <h2 className="text-warning">2. Subscription Charges</h2>
                    <p>When you subscribe to vocabwhiz.com, you agree to pay the subscription fee at specified intervals. You can choose subscription with recurring charge at 12 months, 6 months, 3 months and every month.</p>


                    <h2 className="text-warning">3. Refund Policy</h2>

                    <p>Vocabwhiz.com has a strict <span className="text-warning">NO-REFUND POLICY</span> once the subscription fee has been charged to your account. This policy applies to all paid subscription plans, including renewals.</p>

                    <p>If you wish to avoid future charges, you must cancel your subscription before the next billing cycle begins. You can cancel your subscription at any time by logging into your account, and visiting <a href="/member">My Account</a> page and following the cancellation instructions.</p>
                    
                    <h2 className="text-warning">4. Cancellation</h2>

                    <p>You have the flexibility to cancel your subscription at any time. Your subscription will remain active until the end of the current billing period, and no additional charges will be applied thereafter.</p>
                    <p>To cancel your subscription, log into your account, visit <a href="/member">My Account</a> page and follow the manage subscription instructions.</p>

                    <h2 className="text-warning">5. Contact Us</h2>

                    <p>If you encounter any issues or have questions regarding your subscription or our refund policy, please feel free to contact our customer support team at <span className="text-info">contact@vocabwhiz.com</span> </p>
    

                    <h2 className="text-warning">6. Changes to Refund Policy</h2>

                    <p>vocabwhiz.com reserves the right to make changes to this refund policy at any time. Any modifications to the policy will be posted on our website, and the revised policy will be effective immediately upon posting.</p>



                    <p>By continuing to use vocabwhiz.com, you acknowledge and agree to abide by the terms and conditions outlined in this refund policy.</p>

                    <p>Thank you for choosing vocabwhiz.com. We appreciate your business and look forward to serving you!</p>

                    </div>


                </div>

            </div>
        </>
    )

}

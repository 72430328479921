import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import "./App.css";
import Image from 'react-bootstrap/Image'
import { GiHamburgerMenu } from "react-icons/gi";
import logo from './logo.svg';

import Routes from "./Routes";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "./lib/contextLib";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { onError } from "./lib/errorLib";

function App() {
  const nav = useNavigate();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [user, setUser] = useState(null)
  const [isAdminUser, setIsAdminUser] = useState(false)
  const [expanded, setExpanded] = useState(false);
  
  const toggleNavbar = () => {
    console.log(`inside toggleNavbar: ${expanded}`)
    setExpanded(!expanded);
  };


  useEffect(() => {

    async function onLoad() {
      console.log('In onLoad Function: ', isAuthenticated)
      try {
        await Auth.currentSession();
        userHasAuthenticated(true);
        let user = await Auth.currentAuthenticatedUser()
        setUser(user)
        // console.log('Auth User:', user)
        // console.log(`USer name: ${user.name}`)
        // console.log(`USer pic: ${user.image}`)
        // console.log(`user.signInUserSession.accessToken = ${user.signInUserSession.accessToken}`)
        // console.log(`user.signInUserSession.idToken = ${user.signInUserSession.idToken}`)

        const groups = user.signInUserSession.accessToken.payload["cognito:groups"]
        console.log(groups)
        setIsAdminUser(groups.includes('AdminUser'))
  
        //console.log(`Is it admin user? ${isAdminUser}`)
  
      } catch (e) {
        console.log('Err APP', e)
        if (e !== "No current user") {
          onError(e);
        } 
        // else {
        //   console.log("No curr user, redirecting to HOME PAGE")
        //   nav("/")
        // }
      }
    
      setIsAuthenticating(false);
    }    


    onLoad();
  }, [isAuthenticated, isAdminUser]);
  

  async function handleLogout() {
    toggleNavbar()
    await Auth.signOut();
    setUser(null)
    userHasAuthenticated(false);
    nav("/login")
  }
  
  
  return (
    !isAuthenticating && (
      <div className="App container py-3">
          <center>
        <Navbar collapseOnSelect bg="light" expand="md" className="mb-3 px-3">
          <LinkContainer to="/">
            <Navbar.Brand className="fw-bold text-muted"><a href="/"><img alt="Vocabwhiz.com" border={0} src={logo} width={208} height={64}/></a></Navbar.Brand>
          </LinkContainer>
          {/* <Navbar.Toggle /> */}

          <Navbar.Toggle onClick={toggleNavbar} aria-controls="basic-navbar-nav">
          <span>
          {isAuthenticated ? (<Image src={user?.attributes?.picture} alt={user?.attributes?.given_name} roundedCircle={true} style={{ borderRadius: "50%", height: "40px", width: "40px" }}/>)
          : <GiHamburgerMenu size={36}/>
          }
          </span>
        </Navbar.Toggle>


          <Navbar.Collapse className={expanded ? 'justify-content-end show' : 'justify-content-end'}>
            <Nav activeKey={window.location.pathname}>
              {isAuthenticated ? (
                <>
                  {/* <LinkContainer to="/">
                    <Nav.Link>Hello, {user?.attributes?.given_name}</Nav.Link>
                  </LinkContainer>  */}

                    {/* <Image src={user?.attributes?.picture} alt={user?.attributes?.given_name} roundedCircle={true} style={{ borderRadius: "50%", height: "38px", width: "38px" }}/> */}

                  {isAdminUser ? (
                  <LinkContainer to="/admin/addword">
                    <Nav.Link>Add Word</Nav.Link>
                  </LinkContainer>                     
                  ) : ( <></>)
                  }
                  <LinkContainer to="/list">
                    <Nav.Link onClick={toggleNavbar}>Words</Nav.Link>
                  </LinkContainer> 

                 <LinkContainer to="/search">
                    <Nav.Link onClick={toggleNavbar}>Search</Nav.Link>
                  </LinkContainer>

                  <LinkContainer to="/member">
                    <Nav.Link onClick={toggleNavbar}>My Account</Nav.Link>
                  </LinkContainer>

                  <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                </>
              ) : (
                <>
                  <LinkContainer to="/list">
                    <Nav.Link onClick={toggleNavbar}>Words</Nav.Link>
                  </LinkContainer> 
                                  
                  <LinkContainer to="/login">
                    <Nav.Link onClick={toggleNavbar}>Login</Nav.Link>
                  </LinkContainer>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, isAdminUser, setIsAdminUser, user, setUser }}>
          <Routes />
        </AppContext.Provider>
        </center>

      <center className="text-white" style={{paddingTop: "60px"}}>
        <br/><br/>
        <a className="text-white" href="/words/index.html">All Words</a> | <a className="text-white" href="/privacy">Privacy</a> | <a className="text-white"href="/terms">Terms Of Use</a> | <a className="text-white" href="/refundpolicy">Refund Policy</a> | <a className="text-white" href="/contactus">Contact Us</a> | <a className="text-white" href="/faq">FAQ</a>
      </center>
      </div>
    )
  );

}
export default App;

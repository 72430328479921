import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppContext } from "../lib/contextLib";

export default function AuthenticatedRoute({ children }) {
  const { pathname, search } = useLocation();
  const { isAuthenticated, isAdminUser } = useAppContext();

  console.log(`isAuthenticated::${isAuthenticated}`)
  console.log(`isAdminUser::${isAdminUser}`)  
  console.log(`pathname: ${pathname}`)


  if (!isAuthenticated) {
    //write to localStorage
      localStorage.setItem('redirectUponLogin', pathname);    
    return <Navigate to={`/login?redirect=${pathname}${search}`} />;
  }

  if(pathname.indexOf('admin') !== -1 && !isAdminUser) {
    return <Navigate to={`/`} />;
  }

  return children;
}

import React from "react";
import "./NotFound.css";

export default function NotFound() {
  return (
    <div className="d-flex">
      <div className="text-center w-75 mx-auto text-white">

          <div className="text-white" style={{textAlign: 'left'}}>
          <h2 className="text-warning">Page Not Found</h2>

          <p>Seems like you have reached a page that does not exist. </p>

          </div>


      </div>

</div>

  );
}

import React from "react";
import "./Home.css";

export default function Home() {
  return (
    <div className="d-flex">


      <div className="text-center w-75 mx-auto">
        <h1 className="text-warning fs-3 pt-2">Welcome to VocabWhiz.Com - Ultimate Vocabulary Building Hub!</h1>
        <h2 className="text-info fs-3 pt-2">Unlock the Power of Words Like Never Before</h2>
        <p className="fs-6 text-white" style={{textAlign: 'left'}}>
          Are you ready to embark on an exciting journey of language mastery? At VocabWhiz.Com, we offer a one-of-a-kind learning experience that goes beyond the ordinary. We believe that words are the building blocks of communication and knowledge. That's why we've created a platform that combines innovative teaching methods with engaging features to help you supercharge your vocabulary.
          <br/>
          Here's what sets us apart:
        </p>

        <hr className="text-muted" />


              <div className="row row-cols-1 row-cols-md-2">

                  <div className="col mt-2" key="1">
                    <div className="card text-center">
                      <div className="card-header">
                        <h4 className="text-success fw-bold fs-5">Speak Your Way to Fluency</h4>
                      </div>
                      <div className="card-body">
                        <b className="text fw-light">
                          Experience a unique approach to learning with both text and speech. Our scientifically proven method enhances your ability to remember and apply words effortlessly. Speak, listen, and learn in a way that suits your style.
                        </b>
                      </div>
                    </div>
                  </div>

                  <div className="col mt-2" key="2">
                    <div className="card text-center">
                      <div className="card-header">
                        <h4 className="text-success fw-bold fs-5">Examples that Illuminate</h4>
                      </div>
                      <div className="card-body">
                        <b className="text fw-light">
                        Say goodbye to vague definitions. We provide comprehensive word definitions accompanied by real-life examples, making it easier for you to grasp the context and usage of each word.
                        </b>
                      </div>
                    </div>
                  </div>      


                  <div className="col mt-2" key="3">
                    <div className="card text-center">
                      <div className="card-header">
                        <h4 className="text-success fw-bold fs-5">Spell It Out Loud</h4>
                      </div>
                      <div className="card-body">
                        <b className="text fw-light">
                        Perfect your spelling skills with our audio pronunciation feature. Ideal for spelling competitions and young learners, you can now hear the correct pronunciation and learn how to spell words like a pro.
                        </b>
                      </div>
                    </div>
                  </div>

                  <div className="col mt-2" key="4">
                    <div className="card text-center">
                      <div className="card-header">
                        <h4 className="text-success fw-bold fs-5">Build Your Personal Vocabulary List</h4>
                      </div>
                      <div className="card-body">
                        <b className="text fw-light">
                        Tailor your learning experience by creating your custom word lists. Whether you're preparing for an exam, writing a paper, or just exploring a new language, you can curate your own vocabulary journey.
                        </b>
                      </div>
                    </div>
                  </div>                                 

                  <div className="col mt-2" key="3">
                    <div className="card text-center">
                      <div className="card-header">
                        <h4 className="text-success fw-bold fs-5">Search and Discover</h4>
                      </div>
                      <div className="card-body">
                        <b className="text fw-light">
                        Our user-friendly search feature lets you explore a vast repository of words. Instantly find the words you're looking for, discover their meanings, and hear them spoken aloud..
                        </b>
                      </div>
                    </div>
                  </div>

                  <div className="col mt-2" key="6">
                    <div className="card text-center">
                      <div className="card-header">
                        <h4 className="text-success fw-bold fs-5">Specialized Lists for Every Aspiration</h4>
                      </div>
                      <div className="card-body">
                        <b className="text fw-light">
                        Are you gearing up for the ACT, GRE, GMAT, SAT or ISEE exams? We've got you covered with specialized vocabulary lists at different skill levels. Ace your standardized tests with confidence.
                        </b>
                      </div>
                    </div>
                  </div>  

              </div>

              <div className="text-info pt-4" >
                
              <p className="fs-6 text-blue" style={{textAlign: 'left'}}>
                Elevate your language skills, enhance your academic performance, and communicate with precision. Join us on this thrilling adventure towards becoming a word wizard. Start your journey to linguistic excellence today!
              </p>
              <p className="fs-6 text-blue" style={{textAlign: 'left'}}>
                Begin your exploration and take the first step towards mastering the art of words. Your vocabulary upgrade is just a click away.
              </p>
                
              </div>



      </div>




    </div>


  );
}

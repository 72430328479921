import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from 'axios';
import { Auth } from "aws-amplify";
import "./MyAccount.css";
import LoaderButton from './LoaderButton'
import { useNavigate } from "react-router-dom";
import config from '../config';
const BASE_URL = config.API_BASE_PATH

export default function Listing() {

  const [categoriesData, setCategoriesData] = useState(null)
  const [iseeCategoriesData, setIseeCategoriesData] = useState(null)
  const [greCategoriesData, setGreCategoriesData] = useState(null)
  const [satCategoriesData, setSatCategoriesData] = useState(null)
  const [byLevelCategoriesData, setByLevelCategoriesData] = useState(null)
  const [user, setUser] = useState(null);
  const nav = useNavigate();
  useEffect(() => {

    async function onLoad() {
      console.log('In the Listing onLoad Function')
      try {

        // load the categories
      }
      catch (err) {
        console.log(`Error getting user from session ${JSON.stringify(err)}`)
        //window.location = "/login"
        if(!user) {
          window.location = "/login"
        }
      }

    }
    onLoad()
  }, [])

  useEffect(() => {

    const greCategories = {
      "data": [
        {
          "id": "grewords1",
          "name": "GRE List 1",
          "desc1": "1200 frequently appeared words",
          "buttonLabel": "Start Learning"
        },
        {
          "id": "grewords2",
          "name": "GRE List 2",
          "desc1": "1200 frequently appeared words",
          "buttonLabel": "Start Learning"
        },
        {
          "id": "grewords3",
          "name": "GRE List 3",
          "desc1": "1200 frequently appeared words",
          "buttonLabel": "Start Learning"
        }, 
        {
          "id": "grewords4",
          "name": "GRE List 4",
          "desc1": "1200 frequently appeared words",
          "buttonLabel": "Start Learning"
        }               
      ]
    }

    const satCategories = {
      "data": [
        {
          "id": "satwords1",
          "name": "SAT List 1",
          "desc1": "1200 frequently appeared words",
          "buttonLabel": "Start Learning"
        },
        {
          "id": "satwords2",
          "name": "SAT List 2",
          "desc1": "1200 frequently appeared words",
          "buttonLabel": "Start Learning"
        },
        {
          "id": "satwords3",
          "name": "SAT List 3",
          "desc1": "1200 frequently appeared words",
          "buttonLabel": "Start Learning"
        }              
      ]
    }



    const iseeCategories = {
      "data": [
        {
          "id": "iseeupper",
          "name": "ISEE* Upper List",
          "desc1": "More than 1200 frequently appeared words",
          "buttonLabel": "Start Learning"
        },
        {
          "id": "iseemiddle",
          "name": "ISEE* Middle List",
          "desc1": "More than 1000 frequently appeared words",
        },    
        {
          "id": "iseelower",
          "name": "ISEE* Lower List",
          "desc1": "More than 900 frequently appeared words",
        }
      ]
    }

    const byLevelCategories = {
      "data": [
        {
          "id": "beginner",
          "name": "Beginner List",
          "desc1": "More than 820 Words",
        },
        {
          "id": "intermediate",
          "name": "Intermediate List",
          "desc1": "More than 1200 Words",
        },    
        {
          "id": "advanced",
          "name": "Advanced List",
          "desc1": "More than 1200 Words",
        }            
      ]
    }    

    setIseeCategoriesData(iseeCategories)
    setByLevelCategoriesData(byLevelCategories)
    setGreCategoriesData(greCategories)
    setSatCategoriesData(satCategories)

  }, [])


  async function startLearning(id) {

      //window.location = `/list/${id}`
      nav(`/list/${id}`)

  }


  return (

    <div className="d-flex">

      <div className="text-center w-75 mx-auto">
        <h1 className="text-white fs-3 pt-4">Words - Listing</h1>
        <p className="fs-6 text-muted">
          Various words listing. ISEE (Upper, Middle, Lower), Gradewise, GRE etc
        </p>

        <hr className="text-muted" />

          <>

          <p style={{textAlign: "left"}} className="fs-6 text-white">
            <h2 className="text-info">GRE WORDS</h2>
            Learn over 4800 frequently used words in the GRE Verbal and Comprehension sections. The words are divided into 4 lists of 1200 words each.
          </p>          
            <main>
              <div className="row row-cols-1 row-cols-md-2">

                {greCategoriesData?.data?.map((item, idx) => (

                  <div className="col mt-2" key={item.id}>
                    <div className="card text-center">
                      <div className="card-header">
                        <h4 className="fw-bold fs-5">{item.name}</h4>
                      </div>
                      <div className="card-body">
                        <h1 className="card-title fs-5">
                          <small className="text-muted fw-light">{item.desc1}</small><br />
                        </h1>
                        <LoaderButton
                          onClick={() => startLearning(item.id)}
                          className="btn btn-lg btn-success w-100 text-white">Start Learning</LoaderButton>
                      </div>
                    </div>
                  </div>

                ))}

              </div>
               <hr className="text-muted" />
               </main>

          <p style={{textAlign: "left"}} className="fs-6 text-white">
            <h2 className="text-info">SAT WORDS</h2>
            Learn 3600 frequently used words in the SAT Verbal and Comprehension sections. The words are divided into 3 lists of 1200 words each.
          </p>          
            <main>
              <div className="row row-cols-1 row-cols-md-2">

                {satCategoriesData?.data?.map((item, idx) => (

                  <div className="col mt-2" key={item.id}>
                    <div className="card text-center">
                      <div className="card-header">
                        <h4 className="fw-bold fs-5">{item.name}</h4>
                      </div>
                      <div className="card-body">
                        <h1 className="card-title fs-5">
                          <small className="text-muted fw-light">{item.desc1}</small><br />
                        </h1>
                        <LoaderButton
                          onClick={() => startLearning(item.id)}
                          className="btn btn-lg btn-success w-100 text-white">Start Learning</LoaderButton>
                      </div>
                    </div>
                  </div>

                ))}

              </div>
               <hr className="text-muted" />
               </main>



          <p style={{textAlign: "left"}} className="fs-6 text-white">
            <h2 className="text-info">ISEE WORDS</h2>
            Learn over 3000 frequently used words in the ISEE* Verbal and Comprehension sections.
          </p>          
            <main>
              <div className="row row-cols-1 row-cols-md-2">

                {iseeCategoriesData?.data?.map((item, idx) => (

                  <div className="col mt-2" key={item.id}>
                    <div className="card text-center">
                      <div className="card-header">
                        <h4 className="fw-bold fs-5">{item.name}</h4>
                      </div>
                      <div className="card-body">
                        <h1 className="card-title fs-5">
                          <small className="text-muted fw-light">{item.desc1}</small><br />
                        </h1>
                        <LoaderButton
                          onClick={() => startLearning(item.id)}
                          className="btn btn-lg btn-success w-100 text-white">Start Learning</LoaderButton>
                      </div>
                    </div>
                  </div>

                ))}

              </div>
               <hr className="text-muted" />
               </main>
               <main>
                <p style={{textAlign: "left"}} className="fs-6 text-white">
                <h2 className="text-info">WORDS BY LEVEL</h2>
                  Learn over 3200 words categorized in Beginner, Intermediate and Advanced levels.
                </p>                    
               <div className="row row-cols-1 row-cols-md-2">

                {byLevelCategoriesData?.data?.map((item, idx) => (

                  <div className="col mt-2" key={item.id}>
                    <div className="card text-center">
                      <div className="card-header">
                        <h4 className="fw-bold fs-5">{item.name}</h4>
                      </div>
                      <div className="card-body">
                        <h1 className="card-title fs-5">
                          <small className="text-muted fw-light">{item.desc1}</small><br />
                        </h1>
                        <LoaderButton
                          onClick={() => startLearning(item.id)}
                          className="btn btn-lg btn-success w-100 text-white">Start Learning</LoaderButton>
                      </div>
                    </div>
                  </div>

                ))}

                </div>
                <hr className="text-muted" />               

               <p className="text-muted">* ISEE® is a registered trademark of the Educational Records Bureau which is neither affiliated with nor endorses Vocabwhiz.</p>


            </main>
            


          </>
        




      </div>




    </div>
  );
}

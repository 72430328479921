
import "./Privacy.css";
export default function Privacy() {


    return (
        <>
            <div className="d-flex">
                <div className="text-center w-75 mx-auto text-white">
                    <h1 className="text-white fs-3 pt-4">Privacy Policy</h1>
                    <p className="fs-6 text-muted">
                        Last Updated: [October 24th, 2023]
                    </p>

                    <div className="text-white" style={{textAlign: 'left'}}>
                    <h2 className="text-warning">Introduction</h2>

                    <p>Welcome to <span className="text-info">VocabWhiz.Com</span> ("we," "our," or "us"). At vocabwhiz.com, we take your privacy seriously. This Privacy Policy is designed to help you understand how we collect, use, disclose, and safeguard your personal information when you visit our website or use our services.</p>

                    <p>By using our website or services, you consent to the practices described in this Privacy Policy.</p>

                    <h2 className="text-warning">Information We Collect</h2>

                    <h3 className="text-info">Personal Information</h3>

                    <p>We may collect personal information that you provide to us directly when you:</p>

                    <ul>
                        <li>Sign up for an account</li>
                        <li>Make a purchase</li>
                        <li>Subscribe to our newsletter</li>
                        <li>Contact us through our contact form</li>
                        <li>Participate in surveys or contests</li>
                        <li>Interact with us on social media</li>
                    </ul>

                    <p>This personal information may include but is not limited to your name, email address, postal address, phone number, and payment information.</p>

                    <h3 className="text-info">Non-Personal Information</h3>

                    <p>We also collect non-personal information automatically when you visit our website. This may include:</p>

                    <ul>
                        <li>Your IP address</li>
                        <li>Browser type and version</li>
                        <li>Operating system</li>
                        <li>Date and time of your visit</li>
                        <li>Referring website</li>
                        <li>Pages visited</li>
                        <li>Demographic information</li>
                    </ul>

                    <h2 className="text-warning">How We Use Your Information</h2>

                    <p>We may use your personal information for various purposes, including:</p>

                    <ul>
                        <li>To provide and improve our services</li>
                        <li>To process transactions and fulfill orders</li>
                        <li>To send you updates and promotional materials</li>
                        <li>To respond to your inquiries and provide customer support</li>
                        <li>To conduct research and analyze website usage</li>
                    </ul>

                    <p>We do not sell or rent your personal information to third parties.</p>

                    <h2 className="text-warning">Cookies and Tracking Technologies</h2>

                    <p>We use cookies and similar tracking technologies to collect and store non-personal information about your interactions with our website. You can control the use of cookies through your browser settings.</p>

                    <h2 className="text-warning">Disclosure of Your Information</h2>

                    <p>We may share your personal information with:</p>

                    <ul>
                        <li>Service providers who assist us in operating our website and services</li>
                        <li>Legal authorities when required by law or to protect our rights</li>
                        <li>Business partners for joint marketing purposes (with your consent)</li>
                    </ul>

                    <h2 className="text-warning">Security</h2>

                    <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, or alteration. However, no data transmission over the internet or electronic storage is 100% secure. Please take appropriate precautions when sharing personal information online.</p>

                    <h2 className="text-warning">Your Choices</h2>

                    <p>You may have the following rights regarding your personal information:</p>

                    <ul>
                        <li>Access: You can request access to the personal information we hold about you.</li>
                        <li>Correction: You can correct inaccuracies in your personal information.</li>
                        <li>Deletion: You can request the deletion of your personal information.</li>
                        <li>Opt-out: You can opt-out of receiving marketing communications from us.</li>
                    </ul>

                    <h2 className="text-warning">Changes to this Privacy Policy</h2>

                    <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will post any changes on this page, and the revised policy will be effective when it is posted.</p>

                    <h2 className="text-warning">Contact Us</h2>

                    <p>If you have questions or concerns about this Privacy Policy or our data practices, please contact us at <span className="text-info">contact@vocabwhiz.com</span>.</p>


                    </div>


                </div>

            </div>
        </>
    )

}


import React, { useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { IoAddCircle, IoRemoveCircle } from "react-icons/io5";
import "./FAQ.css";
function FAQ() {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleQuestionClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
      };

    const faqData = [
        {
          question: 'How do I create an account on your website?',
          answer: "We use Google Social Login for user signin. So you just have to authenticate your account with Google to continue using our website."
        },
        {
            "question": "Can I log in using my Google account?",
            answer: "Absolutely! As of right now, Google Social Signin is the only way to log into our website."

        },
        {
            question: 'Do you store password on your website?',
            answer: "We only store your email, first name, last name and google id. As we use Google Social Signin, we <b style=\"color:orange\">DO NOT</b> store your password on our website."
        },               
        {
          question: 'What payment methods do you accept?',
          answer: 'We accept credit and debit cards for payments through our secure payment processor, Stripe.',
        },
        {
            question: 'Do you store my credit card information on your site?',
            answer: "We <b style=\"color:orange\">DO NOT</b> store any credit card information on our site. We use <b style=\"color:orange\">STRIPE</b> as payment processor. When you are ready to purchase, you are taken to Stripe website, you finish your payment and return to our site to continue.",
          },        
        {
          question: 'Can I cancel my subscription?',
          answer: "Absolutely! You can cancel your subscription at any time by visiting <a href=\"/member/subscriptions\">Manage Subscription</a> page. Once cancelled, your subscription will continue to be valid till the end date based on your purchase. Once expired, it will not renew.",
        },
        {
            question: 'Can I renew my cancelled subscription?',
            answer: "Absolutely, you can renew your subscription at any time by visiting <a href=\"/member/subscriptions\">Manage Subscription</a> page. Pickup the recurring billing frequency and finish the payment on Stripe site.",
        },  
        {
            question: 'Can I change to a different subscription plan?',
            answer: "To change to another plan, you have to cancel the existing subscription so that it doesn't automatically renew. Once the existing subscription expires, you can visit <a href=\"/member/subscriptions\">Manage Subscription</a> page to choose a plan that you like and finish the payment on Stripe Site.",
        }, 
        {
            question: 'Are subscriptions refundable if I change my mind?',
            answer: "<b style=\"color:orange\">NO!</b> We do not offer refunds on subscriptions. You can cancel the subscription and it will not renew once it expires. For more information, please visit the <a href=\"/refundpolicy\">Refund Policy</a> Page.",
          },         
        {
            question: 'What features are available in the free version of the website?',
            answer: "Free membership has all the features of the premium membership, but in smaller scope. For example: <ul style=\"color:orange\"><li>You can only see the upto 3 words in each list with free version</li> <li>Search is limited to 5 searches a day.</li> <li>Adding words to your own list is limited to 5 words.</li></ul>",
        }, 
        {
            question: 'Do you offer free trial?',
            answer: "We <b style=\"color:orange\">DO NOT</b> offer free trial. We do provide limited scope access to all the premium features to the FREE members, so they can try before upgrading.",
        },    
        {
            question: 'What happens if I downgrade from a premium subscription to the free version?',
            answer: "After you downgrade (when current subscription doesn't renew), you will have the same access as <b style=\"color:orange\">FREE User</b>.",
        },         
        
        
                  
      ];    

    //   return (
    //     <div>
    //       {faqData.map((faq, index) => (
    //         <div key={index}>
    //           <div
    //             className="faq-question"
    //             onClick={() => handleQuestionClick(index)}
    //           >
    //             {faq.question}
    //             {activeIndex === index ? '-' : '+'}
    //           </div>
    //           {activeIndex === index && (
    //             <div className="faq-answer">{faq.answer}</div>
    //           )}
    //         </div>
    //       ))}
    //     </div>
    //   );



    //   return (
    //     <Accordion activeKey={activeKey}>
    //       {faqData.map((faq, index) => (
    //         <Card key={index}>
    //           <Accordion.Toggle as={Card.Header} eventKey={index} onClick={() => handleAccordionClick(index)}>
    //             {activeKey === index ? <IoRemoveCircle /> : <IoAddCircle />} {faq.question}
    //           </Accordion.Toggle>
    //           <Accordion.Collapse eventKey={index}>
    //             <Card.Body>{faq.answer}</Card.Body>
    //           </Accordion.Collapse>
    //         </Card>
    //       ))}
    //     </Accordion>
    //   );


    return (
        <>
            <div className="d-flex">
                <div className="text-center w-75 mx-auto text-white">
                    <h1 className="text-warning fs-3 pt-4">Frequently Asked Questions</h1>


                    <div className="text-white" style={{textAlign: 'left'}}>    
                        <p className="text-info"></p>
                        {faqData.map((faq, index) => (
                            <div key={index}>
                                <div
                                    className="faq-question" 
                                    style={{paddingTop: '8px'}}
                                    onClick={() => handleQuestionClick(index)}
                                >
                                    {activeIndex === index ? <IoRemoveCircle/> : <IoAddCircle />}  <span className="text-info">{faq.question}</span>
                                    
                                </div>
                                {activeIndex === index && (
                                    <div className="faq-answer" dangerouslySetInnerHTML={{ __html: faq.answer }} />
                                )}
                            </div>
                        ))}

                    </div>


                </div>

            </div>
        </>
    )

}

export default FAQ;

import React, { useEffect } from "react";


export default function PreLogin() {
  
  
  useEffect(() => {

  async function onLoad() {
    window.location.href = "/"
  }

    onLoad();
  }, []);


  return (
    <div className="Settings">
      Logging in ...
    </div>
  );

}

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { FaVolumeUp, FaVolumeMute } from 'react-icons/fa';



const AudioPlayer = ({ word, audioUrl }) => {

  const [isPlay, setIsPlay] = useState(false);
  const [showAudio, setShowAudio] = useState(false);
  const [playString, setPlayString] = useState('')
  const [pauseString, setPauseString] = useState('')
  //const [audio, SetAudio] = useState(null);
  let audio = useRef();


  useEffect(() => {

    //console.log(`AudioPlayer In useEffect .. ${audioUrl}`)
    //SetAudio(new Audio("https://static.vocabwhiz.com/audio/1f1e18b9-5fe6-44ed-8c29-c3870bb41b14/detail/.ce11a5e2-fdd1-461b-8ecb-fcad27559ba8.mp3"))
    try {
      audio.current = new Audio(audioUrl) 
      //console.log(`Before: ${new Date()}`)
      if (word && word.length < 8) {
        setPlayString('Play')
        setPauseString('Pause')
      }
      else if (word) {
        setPlayString(' ')
        setPauseString(' ')   
      }


      const timer = setTimeout(() => {
        // Code to run after the delay
        setShowAudio(true)
        //console.log(`After: ${new Date()}`)

      }, 1000);

      return () => clearTimeout(timer);
         
      
    }
    catch(err) {
      console.log(`Error while new Audio for ${audioUrl}`)
    }
    
    
    // returned function will be called on component unmount 
  }, [audioUrl])

  useEffect(() => {
    return () => {
        audio.current.pause()
        setIsPlay(false);
        //console.log("in cleanup")
    }
}, [audioUrl])  


  const handleAudio = () => {
    console.log(`In handle Audio: ${isPlay}`)
    if (!isPlay) {

      // audio.current.play()
      // sleep(200)
      // audio.current.pause()
      
      setIsPlay(true);
      audio.current.play();      


    } else {
      setIsPlay(false);
      audio.current.pause();
    }
  };

  return (
    // {showAudio ? (
    //   <div onClick={handleAudio}>
    //   {isPlay ? <FaVolumeMute /> : <FaVolumeUp />}
    // </div>
    // ) : 
    // <div/>
    //      {isPlay ? <><Button onClick={handleAudio}>Stop:{isPlay.toString()}</Button><FaVolumeMute /></> : <><Button onClick={handleAudio}>Play:{isPlay.toString()}</Button><FaVolumeUp /></>}
    //working: {isPlay ? <Button onClick={handleAudio}>Stop:{isPlay.toString()}</Button> : <Button onClick={handleAudio}>Play{isPlay.toString()}</Button>}

    // }
    <div>
      {showAudio ? (

      <div>
      {isPlay ? <Button onClick={handleAudio}> {pauseString} <FaVolumeMute /> </Button> : <Button onClick={handleAudio}>{playString} <FaVolumeUp /></Button>}
    </div>

      ) : (
        <div/>
      )}
    </div>
  )
};

export default AudioPlayer;

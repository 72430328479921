import './flippable-card.css';
import Card from './card/card';
import {CSSTransition} from 'react-transition-group';
import {useEffect, useState} from 'react';

function FlippableCard( {showFront, flashCard, setShowFront} ) {
    
    const [prevFlashCard, setPrevFlashCard] = useState(null);
    //const [showfront, setShowFront] = useState(showFront);

    //console.log(`In Flippable Card: ${JSON.stringify(showFront)}`)


   useEffect(() => {
    //console.log(`In useEffect: ${JSON.stringify(flashCard)}, ${prevFlashCard}`)
    // Check if the flashCard prop has changed
    if (flashCard !== prevFlashCard) {
      // Set showFront to true when flashCard changes
      setShowFront(true);
      
      // Update prevFlashCard to the current value
      setPrevFlashCard(flashCard);
    }
  }, [flashCard]);

    


    // function handleCardClick1(e) {
    //     console.log(`e.clientY = ${e.clientY}:${e.target.getBoundingClientRect().top}`)
    //     //if (e.clientY - e.target.getBoundingClientRect().top <= 100) {
    //         if (e.clientY >= 130) {
    //         setShowFront((v) => !v);
    //       }        
    // }

    const handleCardClick = (event) => {
        const card = event.currentTarget;
        const rect = card.getBoundingClientRect();
        const clickY = event.clientY - rect.top;
        const cardHeight = rect.height;
    
        if (clickY >= (1 / 2) * cardHeight) {
          setShowFront(!showFront);
        }
      };    


    return(
        <>
        <div className="flippable-card-container flex">
            <CSSTransition
                in={showFront}
                timeout={300}
                classNames='flip'
            >
                <Card flashCard={flashCard} onClick={handleCardClick}/>
            </CSSTransition>
        </div>
        </>
    );
}

export default FlippableCard;

import React, {useEffect, useState} from 'react'
import { Button } from 'react-bootstrap'
import { Auth, Hub } from 'aws-amplify'
import btn from '../assets/btn_google_signin_dark_pressed_web@2x.png'
import { useAppContext } from "../lib/contextLib";
import './Login.css'

export default function Reset() {

  function handleReset() {
    try {
        localStorage.removeItem('catalogData')
        localStorage.removeItem('allCats')
        localStorage.removeItem('catGroupsData')
        localStorage.removeItem('catGroups')

        window.location.href = '/list'
    }
    catch(err) {
      console.log(`ERROR:: ${JSON.stringify(err)}`)
    }
  }


  return (
    <div className="Login">
        <center>
        <h3>Reset to refresh the categories and groups</h3>
        <br/>            
        <Button onClick={() => handleReset() }>
            Reset Categories
        </Button>
        </center>
        

    </div>
  )
}

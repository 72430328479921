import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Auth } from "aws-amplify";
import FlippableCard from './flippable-card'
import { Button } from 'react-bootstrap'
import LoaderButton from './LoaderButton';
import './search.css'
import config from '../config';
import { useSpeechRecognition } from 'react-speech-kit';

import 'bootstrap-icons/font/bootstrap-icons.css'
const BASE_URL = config.API_BASE_PATH

const SAMPLE_FLASHCARD =
{
    "word": "Search For A Word",
    "meaning": "Please enter a word and search",
    "examples": [
        "If we have the word in our database, we will show it's meaning, audio and sample example uses"
    ]
}

const Search_FLASHCARD =
{
    "word": "Searching ... ",
    "meaning": "Please enter a word and search",
    "examples": [
        "If we have the word in our database, we will show it's meaning, audio and sample example uses"
    ]
}



export default function Search() {
    const [user, setUser] = useState(null)
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [flashCard, setFlashCard] = useState(SAMPLE_FLASHCARD)
    const [showFront, setShowFront] = useState(true)
    const [showAddToList, setShowAddToList] = useState(false)
    const [isAddingWord,setIsAddingWord] = useState(false)

    const { listen, listening, stop } = useSpeechRecognition({
        //const { listen, stop } = useSpeechRecognition({
        onResult: (result) => {
            console.log(`In OnResult: ${result}`)
            setSearchTerm(result)
        }
    })


    const requestInfo = {
        headers: {
            Authorization: `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`,
            'Content-Type': 'application/json'
        }
    }
    useEffect(() => {

        async function onLoad() {
            console.log('In the Search onLoad Function')
            try {
                let user = await Auth.currentAuthenticatedUser()
                setUser(user)
            }
            catch (err) {
                window.location.href = '/login'
            }

        }
        onLoad()
    }, [])

    //   useEffect(() => {
    //     if (searchTerm.length >= 3) {
    //       // Make an API call here to fetch results based on searchTerm
    //       // Example using fetch:
    //     //   fetch(`API_URL?query=${searchTerm}`)
    //     //     .then((response) => response.json())
    //     //     .then((data) => setResults(data));

    //         setResults([
    //             "cart", "cars", "care", "cardio","carla","carpenter"
    //         ])


    //     } else {
    //       setResults([]); // Clear the results if searchTerm is less than 3 characters
    //     }
    //   }, [searchTerm]);

    const handleInputChange = (e) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
        // setSelectedItem(null); // Reset selected item when user types
        // console.log("setting resulrts")
        // setResults([
        //     {"id": "cart", "word": "Cart"},{"id": "cold", "word": "Cold"},{"id": "cars", "word": "Cars"},{"id": "care", "word": "care"},
        //     {"id": "cardio", "word": "Cardio"},{"id": "carwash", "word": "carwash"},{"id": "carpenter", "word": "Carpenter"}
        // ]) 
        // console.log(`Results:: ${JSON.stringify(results)}`)   
    };

    //   const handleItemSelect = (item) => {
    //     setSelectedItem(item);
    //     setSearchTerm(item.word); // Display selected item's name in the input field
    //   };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowAddToList(false)
        // Handle form submission, e.g., trigger an action with the selected item or searchTerm
        try {
            //if (searchTerm && flashCard.word.toLowerCase().indexOf(searchTerm.trim().toLowerCase()) === -1) {
            if (searchTerm && flashCard.word.toLowerCase() !== searchTerm.trim().toLowerCase()) {
                setFlashCard(Search_FLASHCARD)
                setShowFront(true)
                const searchResponse = await axios.post(`${BASE_URL}/vocab/search`, { "searchTerm": searchTerm.toLowerCase() }, requestInfo)
                console.log(`searchResponse.data == ${JSON.stringify(searchResponse.data)}`)
                setFlashCard(searchResponse.data)
                if(searchResponse.data.id !== "1") {
                    setShowAddToList(true)
                }
            }
        }
        catch (err) {
            console.log(`Error searching`)
            //alert(JSON.stringify(err?.response))
            if (err?.response?.status === 403) {
                alert(err.response.data)
            }
            else {
                window.location.href = '/login'
            }
        }
    };

    async function addToList(word, type) {
        console.log(word + ":" + type)
        setIsAddingWord(true)
        const requestInfo = {
          headers: {
            Authorization: `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`,
            'Content-Type': 'application/json'
          }
        } 
        const payload = {
          word: word,
          type: type
        }
        try {
          let response = await axios.post(`${BASE_URL}/member/addWord`, payload, requestInfo)
    
          console.log(`response = ${JSON.stringify(response)}`)
          if(response.status === 200) {
            alert("Word Added Successfully")
          }
    
        }
        catch (err) {
          console.log(err)
          alert(err?.response?.data)
        }    
    
        setIsAddingWord(false)
      }    

    return (
        // <div className="flex" style={{ color: 'white', fontSize: '13px', verticalAlign: 'middle'}}>
        <center>
            <div id="mainDiv" style={{ fontSize: '13px', color: 'white', paddingBottom: '3px', }}>


                <form onSubmit={handleSubmit}>
                    {/* <input
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          placeholder="Search..."
        />
        &nbsp;&nbsp;
        <Button className="searchButton" variant="primary"
            onClick={handleSubmit}
            > Search </Button> */}

                    <br />
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleInputChange}
                        placeholder="Search..."

                    />&nbsp;
                    {/* <span className="input-group-addon">
        <button onPointerDown={listen} onPointerUp={stop}>
            <i className="bi bi-mic"></i>
        </button>
        </span> */}
                    <Button className="searchButton" variant="primary"
                        onClick={handleSubmit}
                    > Search </Button>

                    {/* {listening && <div>Go ahead I'm listening</div>} */}


                </form>

                <div className="searchResultsContainer">
                    <div>
                        <FlippableCard showFront={showFront} flashCard={flashCard} setShowFront={setShowFront} key={flashCard.word} />
                    </div>
                    {showAddToList ? (
                    
                    <div style={{ paddingBottom: "20px"}}>
                        <LoaderButton className="middle-button" variant="primary"
                            isLoading={isAddingWord}
                            disabled={isAddingWord ? true : false}
                            onClick={() => addToList(flashCard.word,flashCard.type)}
                            >Add To List</LoaderButton>                        
                    </div>
                    ):('')}

                </div>

            </div>
        </center>
    );
}


import "./ContactUs.css";
export default function ContactUs() {


    return (
        <>
            <div className="d-flex">
                <div className="text-center w-75 mx-auto text-white">
                    <h1 className="text-white fs-3 pt-4">Contact Us</h1>

                    <div className="text-white" style={{textAlign: 'left'}}>    
                        <p>Thank you for choosing <span className="text-info">vocabwhiz.com</span>! <br/> For frequently asked questions, please visit <a href="/faq">FAQ</a> page.<br/>You can reach us at <span className="text-info">contact@vocabwhiz.com</span>.</p>
                    </div>

                </div>

            </div>
        </>
    )

}

import "./card.css";
import "./flip-transition.css";

import AudioPlayer from "../AudioPlayer"
import React, { useState, useEffect } from 'react';
{/* <div>
AA<AudioPlayer audioUrl="https://static.vocabwhiz.com/audio/1f1e18b9-5fe6-44ed-8c29-c3870bb41b14/detail/.ce11a5e2-fdd1-461b-8ecb-fcad27559ba8.mp3"/>BB
</div> */}

function underlineWord(word, example) {
  let elementArr = example.split(' ')
  if (word.toLowerCase()[word.length - 1] === 'y') {
    word = word.substring(0,word.length - 1)
  }
  for (let index = 0; index < elementArr.length; index++) {
    let wordAtIndex = elementArr[index];
    if(wordAtIndex.toLowerCase().indexOf(word.toLowerCase()) !== -1) {
      wordAtIndex = `<span class="exampleHighlight">${wordAtIndex}</span>`
      elementArr[index] = wordAtIndex
      break
    }
  }
  return elementArr.join(' ')
}

function Card({flashCard, onClick}) {
  //console.log(`flashCard:: ${JSON.stringify(flashCard)}`)

  // useEffect(() => {
  //   console.log(`Card Component useEffect`)

  //   return(() => {
  //     console.log(`Card Component useEffect Return`)
  //   })
  // }, [])

  return (
    <div className="card" onClick={onClick}>
      <div className="card-back">
      {/* <div>
        <AudioPlayer audioUrl="https://static.vocabwhiz.com/audio/1f1e18b9-5fe6-44ed-8c29-c3870bb41b14/detail/.ce11a5e2-fdd1-461b-8ecb-fcad27559ba8.mp3"/>
      </div> */}
          <center><div className="backWord">{flashCard.word} &nbsp; {flashCard.audioUrl ? ( <AudioPlayer word={flashCard.word} audioUrl={flashCard.audioUrl}/>) : ('')}</div></center>
          <div className="backMeaning">
            {flashCard.meaning}
          </div>

          <div className="backExamples">Examples:</div>
            {flashCard?.examples?.map((element, count) => {
                 
                 return <div className='example' key={count}><span>{++count}. </span>
                 <span dangerouslySetInnerHTML={{ __html: underlineWord(flashCard.word, element) }} />
                 </div>

            })}

            
        </div>
      <div className="card-front">{flashCard.word}</div>
    </div>
  );
}

export default Card;

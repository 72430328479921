const config = {

  API_BASE_PATH: "https://d5k6y68ni9.execute-api.us-west-2.amazonaws.com/prod",
  REACT_APP_USER_POOL_ID: "us-west-2_xw9yZkYk7",
  REACT_APP_USER_POOL_CLIENT_ID: "1d4iigetdbn35dap6h99pjcb41",
  REACT_APP_REDIRECT_SIGNIN: "https://vocabwhiz.com/list",
  REACT_APP_REDIRECT_SIGNOUT: "https://vocabwhiz.com",
  REACT_APP_COGNITO_DOMAIN: "vocabbuilder-prod.auth.us-west-2.amazoncognito.com",
  STRIPE_CUSTOMER_PORTAL_LINK: "https://billing.stripe.com/p/login/eVa8xQ574110b6wbII",

  "prices": [
    {
      "id": "12mo",
      "priceId": "price_1OIM6TI2DsXHFWInAdcHB6Yx",
      "billFreq": "12",
      "billFreqUnit": "Month",
      "price": "99",
      "trial": "7",
      "trialUnit": "Day",
      "name": "VocabWhiz Premium - 12 Months Access",
      "description": "12 Months recurring, unless cancelled.",
      "trialInfo": "7-days free trial"
  },
  {
      "id": "6mo",
      "priceId": "price_1OIMAlI2DsXHFWInf1LnAaXr",
      "billFreq": "6",
      "billFreqUnit": "Month",
      "price": "59",
      "trial": "0",
      "trialUnit": "Day",
      "name": "VocabWhiz - 6 Months Access",
      "description": "6 Months recurring, unless cancelled.",
      "trialInfo": "0-days free trial"
  },        
  {
      "id": "3mo",
      "priceId": "price_1OIMBdI2DsXHFWInkFqIE3tw",
      "billFreq": "3",
      "billFreqUnit": "Month",
      "price": "39",
      "trial": "0",
      "trialUnit": "Day",
      "name": "VocabWhiz - 3 Months Access",
      "description": "3 Months recurring, unless cancelled.",
      "trialInfo": "0-days free trial"
  },                
  {
      "id": "1mo",
      "priceId": "price_1OIMCII2DsXHFWInrxSXPOaH",
      "billFreq": "1",
      "billFreqUnit": "Month",
      "price": "19",
      "trial": "0",
      "trialUnit": "Day",
      "name": "VocabWhiz - Monthly Access",
      "description": "1 Month recurring, unless cancelled.",
      "trialInfo": "0-days free trial"
  }

  ]  

};

export default config;

  import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import NotFound from "./components/NotFound";
import Login from "./components/Login";
import PreLogin from "./components/PreLogin";
import FlashcardList from './components/flashcardList';
//import Upgrade from "./components/Upgrade";
import AuthenticatedRoute from "./components/AuthenticatedRoutes";
import UnauthenticatedRoute from "./components/UnauthenticatedRoutes";
import Search from "./components/Search";
import MyAccount from "./components/MyAccount"
import MySubscriptions from "./components/MySubscriptions";
import MyLists from "./components/MyLists";
import Reset from "./components/Reset";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import RefundPolicy from "./components/RefundPolicy"
import ContactUs from "./components/ContactUs";
import FAQ from "./components/FAQ";
import Listing from "./components/Listing";

export default function Links() {
  return (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/list" element={
            <Listing/>
          } />
        <Route path="/list/:mainId" element={
          <AuthenticatedRoute>
            <FlashcardList/>
          </AuthenticatedRoute>
          } />          
        <Route path="/search" element={
          <AuthenticatedRoute>
            <Search/>
          </AuthenticatedRoute>
          } />

        {/* <Route path="/member/upgrade" element={
          <AuthenticatedRoute>
            <Upgrade/>
          </AuthenticatedRoute>
          } />           */}
        <Route path="/prelogin" element={<PreLogin />} />    
        <Route path="/reset" element={<Reset />} />    
            
        <Route path="/login" element={
        <UnauthenticatedRoute>
          <Login />
        </UnauthenticatedRoute>
        } />
   
      <Route path="/member/" element={
        <AuthenticatedRoute>
          <MyAccount />
        </AuthenticatedRoute>
        } />

      <Route path="/member/subscriptions" element={
        <AuthenticatedRoute>
          <MySubscriptions/>
        </AuthenticatedRoute>
        } />    
      <Route path="/member/lists" element={
        <AuthenticatedRoute>
          <MyLists/>
        </AuthenticatedRoute>
        } />             

        <Route path="/privacy" element={
          <Privacy />
        } />
        <Route path="/terms" element={
          <Terms />
        } /> 
        <Route path="/refundpolicy" element={
          <RefundPolicy />
        } />     
        <Route path="/contactus" element={
          <ContactUs />
        } />  
        <Route path="/faq" element={
          <FAQ />
        } />                      
               
        <Route path="*" element={<NotFound />} />;    
    </Routes>
  );
}

import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from 'axios';
import { Auth } from "aws-amplify";
import "./MyAccount.css";
import LoaderButton from './LoaderButton'
import { Button } from 'react-bootstrap'

import config from '../config';
const BASE_URL = config.API_BASE_PATH

export default function MySubscriptions() {

  const [words, setWords] = useState(null)
  
  const [user, setUser] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)
  useEffect(() => {

    async function onLoad() {
      console.log('In the Subscription onLoad Function')
      try {
        let user = await Auth.currentAuthenticatedUser()
        setUser(user)

        if (!user) {
          //redirect
          window.location = "/login"
        }

        const requestInfo = {
          headers: {
            Authorization: `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`,
            'Content-Type': 'application/json'
          }
        }

        //mylist

        let catGroupsDataResponse = await axios.get(`${BASE_URL}/vocab/MyList/groups/group-1/data?groupDetailOnly=true`, requestInfo)
        let myListData = catGroupsDataResponse.data
        //alert(JSON.stringify(myListData))
        setWords(myListData)

        const subscriptionStatus = await axios.get(`${BASE_URL}/member/checkSubscriptionStatus`, requestInfo)
        console.log(`subscriptionStatus == ${JSON.stringify(subscriptionStatus)}`)
        setSubscriptionStatus(subscriptionStatus?.data)


          //localStorage.setItem('catGroupsData', JSON.stringify(catGroupsData));  
               



      }
      catch (err) {
        console.log(`Error getting user from session ${JSON.stringify(err)}`)
        //window.location = "/login"
      }



    }
    onLoad()
  }, [])


  async function removeWord(word, type) {
    setIsDeleting(true)

    const requestInfo = {
      headers: {
        Authorization: `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`,
        'Content-Type': 'application/json'
      }
    }

    const payload = {
      word: word,
      type: type
    }
    let response
    try {
       response = await axios.post(`${BASE_URL}/member/removeWord`, payload, requestInfo)

      console.log(`response = ${JSON.stringify(response)}`)
      if(response.data.status === 200) {
        alert("Word removed Successfully")
      }

      setWords(response.data)

    }
    catch (err) {
      console.log(err)
      alert(err?.response?.data)

    }    

    setIsDeleting(false)

  }



  return (

    <div className="d-flex">


      <div className="text-center w-75 mx-auto">
        <h1 className="text-white fs-3 pt-4">My Account</h1>
        <p className="fs-6 text-muted">
          Manage your account, subscription, list here.
        </p>
        {subscriptionStatus && (
          <h2 className="text-warning fs-5" style={{ textAlign: "left" }}>My Subscription</h2>        
        )}
        
        {subscriptionStatus?.activeSubscription && (
          <p className="fs-6 text-white" style={{textAlign: 'left'}}>
            You are currently subscribed to "{subscriptionStatus?.currentSubscription.name}". Your subscription is valid until "{subscriptionStatus?.end_date}"
          </p>
        )}
        {!subscriptionStatus?.activeSubscription && subscriptionStatus?.end_date && (
          <p className="fs-6 text-white" style={{textAlign: 'left'}}>
            Your subscription ended on "{subscriptionStatus?.end_date}"
          </p>
        )}

        


        {!subscriptionStatus && (
            <main>
            <div className="row row-cols-1 row-cols-md-2">
            <p className="fs-6 text-white">Loading ...</p>
            </div>
            </main>          
        )}
        {subscriptionStatus && (
          <>
            <main>
              <div className="row row-cols-1 row-cols-md-2">

                <p className="text-white" style={{textAlign: 'left'}}><a className="text-warning" href="/member/subscriptions">Click here to manage your billing / subscriptions</a></p>


              </div>
            </main>

            <hr className="text-muted" />
            <h2 className="text-warning fs-5" style={{ textAlign: "left" }}>My Words List</h2>
            <div className="text-info">
              
                {words && words.length > 0 ? (
                <>
                <ul className="list py-3">
                  {words.map(word => (
                    <li style={{verticalAlign: 'middle', paddingBottom: '5px'}} key={word["key"]}>
                                         

                        <LoaderButton
                          onClick={() => removeWord(word["word"],word["type"])}
                          isLoading={isDeleting}
                          disabled={isDeleting ? true : false}
                          className="btn btn-sm btn-warn text-white">X</LoaderButton>
                          &nbsp; {word["word"]}  


                    </li>
                  ))}
                </ul>
                </>           

                ) : (
                  
                    <p className="text-white">You do not have any words saved to your list. You can add them from 'words' list and search pages.</p>
                  
                )}
             
            </div>
            <hr className="text-muted" />

          </>
        )}


      </div>

    </div>
  );
}

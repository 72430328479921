import React from 'react';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
//import { Provider } from 'react-redux'
import {Amplify, Auth} from 'aws-amplify'
//import store from './store/store'
import config from './config'

// Init Amplify
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: 'us-west-2',
    userPoolId: config.REACT_APP_USER_POOL_ID,
    // identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolWebClientId: config.REACT_APP_USER_POOL_CLIENT_ID,
    storage: localStorage,
    oauth: {
      domain: config.REACT_APP_COGNITO_DOMAIN,
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
      redirectSignIn: config.REACT_APP_REDIRECT_SIGNIN,
      redirectSignOut: config.REACT_APP_REDIRECT_SIGNOUT,
      responseType: 'token'
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
